import { unref as _unref, createElementVNode as _createElementVNode, withDirectives as _withDirectives, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "page-error mb-xxl-2" }
const _hoisted_2 = { class: "heading p-s semi-bold" }
const _hoisted_3 = { class: "body mb-xl pl-m pr-m sub-heading semi-bold" }

import { useI18next } from '@composables/i18next';
    import { useRouter } from 'vue-router';
    import { useBasePage } from '@composables/page';

    
export default {
  __name: 'ErrorPage',
  setup(__props) {

    useBasePage('error');

    const { t, vT } = useI18next('optout');
    const router = useRouter();

    const navigateToOptOut = () => {
        router.replace({ path: '/optout' });
    };

return (_ctx, _cache) => {
  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _withDirectives(_createElementVNode("h3", _hoisted_2, null, 512), [
      [_unref(vT), 'optout.error_headline_copy']
    ]),
    _withDirectives(_createElementVNode("p", _hoisted_3, null, 512), [
      [_unref(vT), 'optout.error_body_copy']
    ]),
    _withDirectives(_createElementVNode("a", {
      href: "#",
      class: "button mb-xxl-2",
      onClick: _cache[0] || (_cache[0] = _withModifiers($event => (navigateToOptOut()), ["prevent"]))
    }, null, 512), [
      [_unref(vT), 'optout.error_cta_copy']
    ])
  ]))
}
}

}