

import { nextTick } from 'vue';
import { mapState, mapGetters } from 'vuex';

import { track } from '../plugins/WebAnalytics';

const focusableSelector = [
    'a[href]',
    'area[href]',
    'input:not([disabled])',
    'select:not([disabled])',
    'textarea:not([disabled])',
    'button:not([disabled])',
    'iframe',
    '[tabindex]',
    '[contentEditable=true]',
].join(',');

export default {
    name: 'BaseModal',

    data () {
        return {
            modalName: '',
            // Set to false to disable the escape key closing the modal. Some modals may require
            // some process to be completed and have a special close handler for the CTA, etc.
            // A fully accessible modal should allow this, so only disable this when necessary,
            // and only in the data of the specific modal(s) that you want it disabled on.
            escapeKeyClosesModal: true,
        };
    },

    computed: {
        // Map state very commonly used by modals, for convenience.
        ...mapState([
            'app',
            'profile',
        ]),

        ...mapGetters({
            isSessionExpired: 'profile/isSessionExpired',
        }),

        focusableElements () {
            return this.$el.querySelectorAll(focusableSelector);
        },
    },

    watch: {
        isSessionExpired () {
            if (this.modalName !== 'session-expiration') {
                this.closeModal();
            }
        },
    },

    beforeMount () {
        document.body.setAttribute('data-e2e-modal', this.modalName);

        this.$store.commit('ui/modalOpen', this);

        track('Open', { category: 'Modal', label: this.modalName });
    },

    mounted () {
        document.body.appendChild(this.$el);

        this.$el.addEventListener('keydown', this.keydownListener);
        nextTick(() => {
            this.focusableElements[0].focus();
        });
    },

    beforeUnmount () {
        document.body.removeAttribute('data-e2e-modal');

        this.$store.commit('ui/modalClose');

        track('Close', { category: 'Modal', label: this.modalName });

        this.$el.removeEventListener('keydown', this.keydownListener);
    },

    methods: {
        // `arg` in either of these methods will end up resolved as the
        // value of the openModal promise
        cancelModal (arg) {
            this.$emit('cancel-modal', arg);
        },

        closeModal (arg) {
            this.$emit('close-modal', arg);
        },

        destroyModal () {
            this.$emit('close');

            if (this.$el.remove !== undefined) {
                this.$el.remove();
            }
            else {
                this.$el.parentNode.removeChild(this.$el);
            }
        },

        keydownListener (event) {
            // Note that all component methods automatically have their `this` context
            // bound to the Vue instance. This is why we do not need to do it manually
            // when registering this method as an event listener above.
            // https://vuejs.org/v2/api/#methods
            const firstFocusableElement = this.focusableElements.item(0),
                lastFocusableElement = this.focusableElements.item(this.focusableElements.length - 1);

            switch (event.code) {
                case ('Tab'):
                    if (event.shiftKey) {
                        if (document.activeElement === firstFocusableElement) {
                            event.preventDefault();
                            lastFocusableElement.focus();
                        }
                    }
                    else {
                        if (document.activeElement === lastFocusableElement) {
                            event.preventDefault();
                            firstFocusableElement.focus();
                        }
                    }
                    break;
                case ('Escape'):
                    if (this.escapeKeyClosesModal) {
                        this.closeModal();
                    }
                    break;
                default:
                    break;
            }
        },
    },
};
