import { createElementVNode as _createElementVNode, unref as _unref, withDirectives as _withDirectives, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "the-footer p-s mb-xl" }
const _hoisted_2 = ["src"]
const _hoisted_3 = { class: "disclaimer mt-xs mb-l" }
const _hoisted_4 = {
  key: 0,
  class: "artificial-tests"
}
const _hoisted_5 = ["textContent"]
const _hoisted_6 = ["src"]

import { useStore } from 'vuex';
    import { computed } from 'vue';

    import { useI18next } from '@composables/i18next';
    import LanguageSelector from '@components/LanguageSelector.vue';

    // Setup
    
export default {
  __name: 'TheFooter',
  setup(__props) {

    const store = useStore();

    const { t, vT } = useI18next(['footer', 'links', 'global']);

    const rulesLink = computed(() => `<a href="${store.state.app.tb2URL}public/${(store.state.app.userLanguage === 'fr') ? 'fr/' : ''}fulfillment/rules.pdf" title="${t('links:rules_copy')} ${t('links:pdf')}" target="_blank">${t('links:rules_copy')}</a>`);

return (_ctx, _cache) => {
  return (_openBlock(), _createElementBlock("footer", _hoisted_1, [
    _createElementVNode("img", {
      src: _ctx.$i18nAsset('img/starbucks-aeroplan.svg'),
      alt: ""
    }, null, 8, _hoisted_2),
    _withDirectives(_createElementVNode("p", _hoisted_3, null, 512), [
      [_unref(vT), { key: 'footer:disclaimer', rulesLink: rulesLink.value, interpolation: { escapeValue: false } }]
    ]),
    _createVNode(LanguageSelector),
    (_unref(store).state.app.isAutomatedTest)
      ? (_openBlock(), _createElementBlock("p", _hoisted_4, [
          _createElementVNode("span", {
            class: "test-copy",
            textContent: _toDisplayString(_unref(t)('testNamespace:testKey'))
          }, null, 8, _hoisted_5),
          _createElementVNode("img", {
            class: "language-flag",
            alt: "Language flag",
            src: _ctx.$i18nAsset('img/flag.svg'),
            style: {"display":"inline","width":"2em","padding":"0 0.5em","vertical-align":"middle"}
          }, null, 8, _hoisted_6)
        ]))
      : _createCommentVNode("", true)
  ]))
}
}

}