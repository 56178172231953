import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["inert"]
const _hoisted_2 = {
  id: "main-section",
  role: "main"
}

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_TheHeader = _resolveComponent("TheHeader")
  const _component_router_view = _resolveComponent("router-view")
  const _component_TheFooter = _resolveComponent("TheFooter")
  const _component_CookieGate = _resolveComponent("CookieGate")

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", {
      class: "wrapper",
      inert: !!_ctx.$store.state.ui.modalName
    }, [
      _createVNode(_component_TheHeader),
      _createElementVNode("main", _hoisted_2, [
        _createVNode(_component_router_view, { class: "content" })
      ])
    ], 8, _hoisted_1),
    (!$data.removeFooter.includes(_ctx.$store.state.ui.pageName))
      ? (_openBlock(), _createBlock(_component_TheFooter, { key: 0 }))
      : _createCommentVNode("", true),
    _createVNode(_component_CookieGate, {
      "body-copy": $setup.t('cookie_gate:alternate_copy'),
      "button-copy": $setup.t('cookie_gate:button_copy'),
      "title-copy": $setup.t('cookie_gate:title')
    }, null, 8, ["body-copy", "button-copy", "title-copy"])
  ], 64))
}