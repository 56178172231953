
import axios from 'axios';
import logClientError from '../client-error';

const AWS_WAF_TOKEN_HEADER = 'x-aws-waf-token';

export default async function optionallyGetAwsWafToken () {
    // `AwsWafIntegration` is loaded by the index EJS template when available.
    // https://docs.aws.amazon.com/waf/latest/developerguide/waf-javascript-api.html
    const { AwsWafIntegration } = window;

    if (AwsWafIntegration) {
        try {
            axios.interceptors.request.use(async (config) => {
                // If the token expires, delay requests until we get a new one.
                if (!AwsWafIntegration.hasToken()) {
                    const token = await AwsWafIntegration.getToken();

                    axios.defaults.headers.common[AWS_WAF_TOKEN_HEADER] = token;
                }
                return config;
            });

            // https://docs.aws.amazon.com/waf/latest/developerguide/waf-js-challenge-api-get-token.html
            const token = await AwsWafIntegration.getToken();

            // Also set the header, for better iframe (or anywhere cookies don't
            // work) support.
            axios.defaults.headers.common[AWS_WAF_TOKEN_HEADER] = token;
        }
        catch (err) {
            logClientError(err);
        }
    }
}
