
import { isPlainObject } from 'lodash';

/*
    Loosely based on
    https://github.com/panter/vue-i18next/blob/master/src/directive.js, but with
    fewer features, and modifies `el.innerHTML` so HTML content can be used in
    copydeck nodes.
*/

function assert (binding) {
    const { instance } = binding;

    if (!instance.$t) {
        console.warn('No $t() method found in the Vue instance');
        return false;
    }
    if (!instance.$i18next) {
        console.warn('No $i18next found in the Vue instance');
        return false;
    }

    return true;
}

function t (el, binding, vnode) {
    const { value } = binding;
    let key;
    let options = {};

    if (isPlainObject(value)) {
        ({ key } = value);
        options = { ...value };
        delete options.key;
    }
    else {
        key = value;
    }

    if (!key) {
        console.warn('v-t: "key" is required', { el, binding, vnode });
        return;
    }

    const { instance } = binding;

    el.innerHTML = instance.$t(key, options);
}

const rerenderOn = ['languageChanged', 'loaded', 'added', 'removed'];
const elsHandlers = new WeakMap();

export function beforeMount (el, binding, vnode) {
    if (!assert(binding)) {
        return;
    }

    const handler = () => t(el, binding, vnode);
    elsHandlers.set(el, handler);

    const { instance: { $i18next: i18next } } = binding;

    // Taken from https://github.com/i18next/i18next-vue/blob/vue-2/index.ts#L44
    rerenderOn.forEach(event => {
        switch (event) {
            case 'added':
            case 'removed':
                i18next.store?.on(event, handler);
                break;
            default:
                i18next.on(event, handler);
                break;
        }
    });

    handler();
}

export function updated (el, binding, vnode, oldVNode) {
    t(el, binding, vnode);
}

export function unmounted (el, binding, vnode) {
    const handler = elsHandlers.get(el);

    if (!handler) {
        return;
    }

    const { instance: { $i18next: i18next } } = binding;

    rerenderOn.forEach(event => {
        switch (event) {
            case 'added':
            case 'removed':
                i18next.store?.off(event, handler);
                break;
            default:
                i18next.off(event, handler);
                break;
        }
    });
}

export default {
    beforeMount,
    updated,
    unmounted,
};
