import { unref as _unref, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "language-selector" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = ["href"]
const _hoisted_4 = { key: 0 }
const _hoisted_5 = ["href"]

import { useStore } from 'vuex';
    import { useI18next } from '@composables/i18next';
    import { computed } from 'vue';

    
export default {
  __name: 'LanguageSelector',
  setup(__props) {

    const store = useStore();
    const app = computed(() => store.state.app);
    const { vT } = useI18next('global', { keyPrefix: 'languageSelector' });


    function getUrlForLocale (locale) {
        let url = app.value.siteURL;
        const suffixUrl = window.location.hash;
        return url = `${url}?locale=${locale}${suffixUrl}`;
    }

    async function handleLocaleClick () {
        try {
            window._analytics.logout();
        }
        catch (error) {
            console.error('[GDS] Logout unsuccessful');
        }
    }

return (_ctx, _cache) => {
  return (_openBlock(), _createElementBlock("ul", _hoisted_1, [
    _createElementVNode("li", null, [
      (_unref(store).state.app.userLanguage === 'en')
        ? _withDirectives((_openBlock(), _createElementBlock("span", _hoisted_2, null, 512)), [
            [_unref(vT), 'desktop.en']
          ])
        : (_openBlock(), _createElementBlock("a", {
            key: 1,
            href: getUrlForLocale('en-CA'),
            onClick: handleLocaleClick
          }, [
            _withDirectives(_createElementVNode("span", null, null, 512), [
              [_unref(vT), 'desktop.en']
            ])
          ], 8, _hoisted_3))
    ]),
    _createElementVNode("li", null, [
      (_unref(store).state.app.userLanguage === 'fr')
        ? _withDirectives((_openBlock(), _createElementBlock("span", _hoisted_4, null, 512)), [
            [_unref(vT), 'desktop.fr']
          ])
        : (_openBlock(), _createElementBlock("a", {
            key: 1,
            href: getUrlForLocale('fr-CA'),
            onClick: handleLocaleClick
          }, [
            _withDirectives(_createElementVNode("span", null, null, 512), [
              [_unref(vT), 'desktop.fr']
            ])
          ], 8, _hoisted_5))
    ])
  ]))
}
}

}