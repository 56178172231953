
import axios from 'axios';
import { debounce } from 'lodash';
import config from 'rocketship-config';
import api from './api';

const
    errorsToIgnore = new Set(["Script error.","Script error"]),
    debounceWait = undefined || 0;

/**
 * Send client-side errors to the server logs for later analysis.
 * @param {ErrorEvent|Error} eventOrError - https://developer.mozilla.org/en-US/docs/Web/API/ErrorEvent
 * @param {Object} [options={}]
 * @param {boolean} [options.fatal] - indicates if this error blocked the user's flow,
 * e.g. by redirecting them to the error page.
 * @returns {Promise}
 */
export default async function logClientError (eventOrError, { fatal } = {}) {
    const error = eventOrError.error ?? eventOrError;
    const { message = 'unknown error', stack, name } = error;
    if (errorsToIgnore.has(message)) return;

    // If NewRelic browser monitoring is on and available (hasn't been blocked
    // by a privacy extension) we want it to notice these errors too.
    if (window.newrelic) {
        try {
            window.newrelic.noticeError(error, { fatal });
        }
        catch (err) {
            console.error('caught NewRelic error', err);
        }
    }

    try {
        await axios.post(`${api.base}/client-error`, {
            message,
            stack,
            name,
            fatal,
        });
    }
    catch (err) {
        console.error('error logging error', err);
    }
}

window.addEventListener(
    'error',
    // Send the first event immediately, then debounce any other errors
    // to protect our servers. https://lodash.com/docs/#debounce
    debounce(
        event => {
            logClientError(event);
            return false;
        },
        debounceWait,
        {
            leading: true,
        },
    ),
);
